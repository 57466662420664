import BaseBean from "@/utils/BaseBean";

export interface IBrandListDataObj {
    utilInst:BrandListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class BrandListUtil extends BaseBean{
    public dataObj:IBrandListDataObj

    constructor(proxy:any,dataObj:IBrandListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}